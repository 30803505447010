<template>
  <v-dialog v-model="dialogAREdit" persistent width="600">
    <v-card v-if="carrier">
      <v-card-title style="background-color: #1e88e5" class="pt-0 pl-0">
        <v-row class="align-center">
          <v-col cols="12" class="mt-4">
            <span class="text-h5 white--text"
              >Accounts Receivable: {{ carrier.name }}</span
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form ref="carrier-ar-form" v-model="valid">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-radio-group
                v-model="selectedPayTo"
                row
                mandatory
                class="mt-1"
                label="Pay To"
                @change="changePayTo"
              >
                <v-radio label="Factoring Company" value="1" />
                <v-radio label="Carrier" value="2" />
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-if="selectedPayTo === '2'"
                v-model="carrier.ar_name"
                type="text"
                label="Name"
                :rules="[rules.required, rules.name]"
              />
              <v-autocomplete
                v-else
                v-model="factoringCompany"
                :items="factoringCompaniesList"
                :search-input.sync="search"
                :loading="factoringLoading"
                color="info"
                clearable
                item-text="name"
                item-value="id"
                label="Factoring Company"
                placeholder="Start typing to Search"
                return-object
                required
                :rules="[(v) => !!v || 'Factoring company is required']"
                validate-on-blur
                close-on-content-click
                @change="factoringSelected"
                @keydown.enter="$event.stopPropagation()"
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.ar_address"
                type="text"
                label="Address"
                :rules="[rules.required]"
                :disabled="selectedPayTo === '1'"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-office-building </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.ar_city"
                type="text"
                label="City"
                :rules="[rules.required]"
                :disabled="selectedPayTo === '1'"
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.ar_state"
                type="text"
                label="State"
                :rules="[rules.required]"
                :disabled="selectedPayTo === '1'"
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.ar_zipcode"
                type="text"
                label="Zipcode"
                :rules="[rules.required, rules.postalCode]"
                :disabled="selectedPayTo === '1'"
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-select
                v-model="carrier.ar_country"
                :items="countries"
                label="Country"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                :disabled="selectedPayTo === '1'"
                clearable
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.ar_phone"
                type="text"
                label="Phone"
                :rules="[rules.required, rules.phone]"
                :disabled="selectedPayTo === '1'"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-phone </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.ar_email"
                type="text"
                label="Email"
                :rules="[rules.required]"
                :disabled="selectedPayTo === '1'"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-email </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.ar_remit_email"
                type="text"
                label="Remit Email"
                :rules="[rules.email]"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-email-variant </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.ar_contact_name"
                type="text"
                label="Contact Name"
                :rules="[rules.alphaNumeric]"
                :disabled="selectedPayTo === '1'"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-account </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.pay_terms"
                type="text"
                label="Pay Terms"
              >
                <template v-slot:prepend>
                  <v-icon color="#1E88E5"> mdi-timer-sand </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-text-field
                v-model="carrier.quickbooks_id"
                type="text"
                label="Quickbooks ID"
                :rules="[rules.intDigit]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn color="grey darken-1" text @click="closeDialog()"> Close </v-btn>
        <v-btn color="orange darken-1" text @click="submitCarrier()">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from "lodash/debounce"
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
import api from "@/api/api"
import common from "@/utils/common"

const snackbarType = common.data.snackbarType

export default {
  name: "CarrierAREdit",
  data() {
    return {
      carrier: {},
      valid: false,
      rules: validation.rules,
      selectedPayTo: 1,
      search: null,
      companies: [],
      factoringLoading: false,
      selectedFactoringCompany: null,
      carrierId: null,
    }
  },
  computed: {
    ...mapGetters("generic", ["loadingIcon", "countries"]),
    ...mapGetters("billing", ["dialogAREdit", "selectedCarrier"]),
    ...mapGetters("factoring", ["currentFactoring"]),
    factoringCompaniesList() {
      return this.companies.map((company) => {
        const name = company.text
        return Object.assign({}, company, { name })
      })
    },
    factoringCompany: {
      get() {
        return this.selectedFactoringCompany
          ? this.selectedFactoringCompany
          : null
      },
      set(newValue) {
        this.selectedFactoringCompany = newValue
      },
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.companies = []
        return
      }
      if (this.factoringCompany) {
        if (val !== this.factoringCompany.name) {
          if (this.factoringLoading) return
          this.factoringLoading = true
          this.getFactoringSearch()
        }
      } else {
        if (this.factoringLoading) return
        this.factoringLoading = true
        this.getFactoringSearch()
      }
    },
    currentFactoring() {
      this.setCurrentFacotringCompanyData()
    },
    dialogAREdit() {
      if (this.dialogAREdit === true) {
        this.carrierARData()
      }
    },
  },
  methods: {
    carrierARData() {
      if (this.selectedCarrier !== null) {
        this.carrier = JSON.parse(JSON.stringify(this.selectedCarrier))
        this.carrierId = this.selectedCarrier.id
        if (this.selectedCarrier.factoring_company === null) {
          this.selectedPayTo = "2"
        } else {
          this.selectedPayTo = "1"
          this.$store.dispatch(
            "factoring/GET_FACTORING_COMPANY",
            this.selectedCarrier.factoring_company.id
          )
        }
      }
    },
    closeDialog() {
      this.$store.commit("billing/setDialogAREdit", false)
      this.$refs["carrier-ar-form"].reset()
      this.$refs["carrier-ar-form"].resetValidation()
    },
    setCarrierARDetailNull() {
      this.carrier.ar_name = null
      this.carrier.ar_address = null
      this.carrier.ar_city = null
      this.carrier.ar_state = null
      this.carrier.ar_zipcode = null
      this.carrier.ar_country = null
      this.carrier.ar_phone = null
      this.carrier.ar_email = null
      this.carrier.ar_contact_name = null
      this.$refs["carrier-ar-form"].resetValidation()
    },
    setCurrentFacotringCompanyData() {
      this.carrier.ar_name = this.currentFactoring.name
      this.carrier.ar_address = this.currentFactoring.address
      this.carrier.ar_city = this.currentFactoring.city
      this.carrier.ar_state = this.currentFactoring.state
      this.carrier.ar_zipcode = this.currentFactoring.postalCode
      this.carrier.ar_country = this.currentFactoring.country
      this.carrier.ar_phone = this.currentFactoring.phone
      this.carrier.ar_email = this.currentFactoring.email
      this.carrier.ar_contact_name = this.currentFactoring.contactName
      this.factoringCompany = {
        id: this.currentFactoring.id,
        text: this.currentFactoring.name,
        name: this.currentFactoring.name,
      }
      this.companies = [
        {
          id: this.currentFactoring.id,
          name: this.currentFactoring.name,
          text: this.currentFactoring.name,
        },
      ]
    },
    changePayTo(value) {
      if (parseInt(value) === 2) {
        if (this.selectedCarrier.factoring_company === null) {
          this.carrier = JSON.parse(JSON.stringify(this.selectedCarrier))
        } else {
          this.setCarrierARDetailNull()
        }
      } else {
        if (this.selectedCarrier.factoring_company === null) {
          this.selectedFactoringCompany = null
          this.setCarrierARDetailNull()
        } else {
          this.setCurrentFacotringCompanyData()
        }
      }
    },
    submitCarrier() {
      if (this.$refs["carrier-ar-form"].validate()) {
        const payload = {
          id: this.carrierId,
          carrier: {
            ar_name:
              this.selectedPayTo === "1"
                ? this.selectedFactoringCompany.text
                : this.carrier.ar_name,
            ar_address: this.carrier.ar_address,
            ar_city: this.carrier.ar_city,
            ar_state: this.carrier.ar_state,
            ar_zipcode: this.carrier.ar_zipcode,
            ar_country: this.carrier.ar_country,
            ar_phone: this.carrier.ar_phone,
            ar_email: this.carrier.ar_email,
            ar_remit_email: this.carrier.ar_remit_email,
            ar_contact_name: this.carrier.ar_contact_name,
            pay_terms: this.carrier.pay_terms,
            quickbooks_id: this.carrier.quickbooks_id,
            factoring_company:
              this.selectedPayTo === "1"
                ? this.selectedFactoringCompany.id
                : null,
          },
        }
        this.$store
          .dispatch("carrier/UPDATE_CARRIER_COMPANY", payload)
          .then((data) => {
            this.$emit("event", { carrier: payload })
            this.$refs["carrier-ar-form"].reset()
            this.$refs["carrier-ar-form"].resetValidation()
            this.$store.commit("billing/setDialogAREdit", false)
          })
      }
    },
    factoringSelected(value) {
      this.$store.dispatch("factoring/GET_FACTORING_COMPANY", value.id)
    },
    getFactoringSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/factoring/autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          this.companies = res.data.results
          this.factoringLoading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Whoops, something went wrong",
            error: err,
            type: snackbarType.error,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
        })
        .finally((this.factoringLoading = false))
    }, 500),
  },
}
</script>
