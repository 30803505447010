<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid style="max-width: 1600px">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search
              <span style="padding-left: 2px">({{ totalRecord }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.loadNumber"
                    label="Load Number"
                    type="text"
                    @keydown.enter="searchAllReceivableLoads()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.customerRefId"
                    label="Customer Ref. Id"
                    type="text"
                    @keydown.enter="searchAllReceivableLoads()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <customer-auto-complete
                    class="pt-3"
                    @event="selectedCustomer"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn
                    class="blue white--text dark-3"
                    small
                    @click="searchAllReceivableLoads()"
                  >
                    <v-icon small> mdi-search-web </v-icon>
                    Filter
                  </v-btn>
                  <v-btn class="ml-1" small @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>
    <v-col cols="12">
      <v-container class="pt-0 data-container" fluid>
        <v-data-table
          :headers="headers"
          :items="receivableLoads"
          :items-per-page="pageSize"
          :loading="loadingIcon"
          calculate-widths
          class="elevation-2 data-table"
          fixed-header
          hide-default-footer
        >
          <template v-slot:item.loadNumber="{ item }">
            <v-btn
              :to="{
                name: 'Load Detail',
                params: { id: item.loadNumber },
              }"
              small
              target="_blank"
              text
            >
              {{ item.loadNumber }}
            </v-btn>
          </template>
          <template v-slot:item.loadStatus="{ item }">
            <load-status-button
              :agent-detail="agentDetail"
              :disable-click="true"
              :load-status-items="loadStatus"
              :shipment-detail="item"
            />
          </template>
          <template v-slot:item.carrier="{ item }">
            <p v-if="item.carrier" class="mb-0">
              <router-link
                :to="{
                  name: 'Carrier Detail',
                  params: {
                    id: item.carrier.id,
                    title: item.carrier.name + ' - ' + item.carrier.mc_number,
                  },
                }"
                class="name-link"
                target="_blank"
              >
                <span>{{ item.carrier.name }}</span>
              </router-link>
            </p>
            <span v-if="item.carrier.ar_name">
              <b>AR:</b> {{ item.carrier.ar_name }}
            </span>
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                name: 'Customer Detail',
                params: { id: item.customer.id },
              }"
              class="name-link"
              target="_blank"
            >
              {{ item.customer.name }}
            </router-link>
          </template>
          <template v-slot:item.shipper="{ item }">
            <p v-if="item.shipper !== null" class="mb-0">
              {{
                formatLocation(
                  item.shipper.city,
                  item.shipper.state_province_region
                )
              }}
              <br />
              <template v-if="item.pickupDate !== null">
                {{ formatDate(item.pickupDate) }}
              </template>
              <template v-if="item.pickupEarlyTime !== null">
                {{ parseTime(item.pickupEarlyTime) }} -
                {{ parseTime(item.pickupLateTime) }}
              </template>
            </p>
          </template>
          <template v-slot:item.consignee="{ item }">
            <p v-if="item.consignee !== null" class="mb-0">
              {{
                formatLocation(
                  item.consignee.city,
                  item.consignee.state_province_region
                )
              }}
              <br />
              <template v-if="item.deliveryDate !== null">
                {{ formatDate(item.deliveryDate) }}
              </template>
              <template v-if="item.deliveryEarlyTime !== null">
                {{ parseTime(item.deliveryEarlyTime) }} -
                {{ parseTime(item.deliveryLateTime) }}
              </template>
            </p>
          </template>
          <template v-slot:item.customerPrice="{ item }">
            <span v-if="item.customerPrice" class="blue--text text--darken"
              >${{ item.customerPrice }}</span
            >
          </template>
          <template v-slot:item.fuelSurcharge="{ item }">
            <span v-if="item.fuelSurcharge" class="blue--text text--darken"
              >${{ item.fuelSurcharge }}</span
            >
          </template>
          <template v-slot:item.customerTotalPrice="{ item }">
            <span v-if="item.customerPrice" class="blue--text text--darken"
              >${{ item.customerTotalPrice }}</span
            >
          </template>
          <template v-slot:item.edit="{ item }">
            <v-icon
              class="px-0"
              small
              title="Edit Carrier AR Info"
              @click="editCarrierARInfo(item.carrier)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.receivePayment="{ item }">
            <v-btn
              class="font-weight-bold green lighten-1"
              small
              @click="
                confirmChangeLoadStatus(
                  item.loadNumber,
                  item.customerTotalPrice
                )
              "
            >
              Receive Payment
            </v-btn>
          </template>
          <template v-slot:footer>
            <v-page
              :page-size-menu="[10, 20, 50, 100]"
              :total-row="totalRecord"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-col>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title />
        <v-card-text>
          <p>Please confirm load amount received:</p>
          <p class="text-center mb-0">
            <b>${{ customerTotalPrice }}</b>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn color="grey darken-1" text @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="orange darken-1" text @click="loadAmountReceived()">
            Receive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <carrier-a-r-edit @event="oprationEvent" />
  </v-row>
</template>
<script>
import { mapGetters } from "vuex"
import moment from "moment"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"
import CarrierAREdit from "@/views/pages/account/CarrierAREdit"
import common from "@/utils/common"
import LoadStatusButton from "@/views/pages/components/LoadStatusButton"
const snackbarType = common.data.snackbarType
export default {
  name: "Receivables",
  components: {
    CustomerAutoComplete,
    CarrierAREdit,
    LoadStatusButton,
  },
  data() {
    return {
      dialog: false,
      loadId: null,
      customerTotalPrice: null,
      searchParam: {
        loadNumber: null,
        customerRefId: null,
        customerId: null,
      },
      headers: [
        {
          text: "Customer",
          align: "center",
          sortable: false,
          value: "customer",
        },
        {
          text: "Load #",
          value: "loadNumber",
          align: "center",
          sortable: false,
          width: "5%",
        },
        {
          text: "Load Status",
          value: "loadStatus",
          align: "center",
          sortable: false,
        },
        {
          text: "Carrier",
          value: "carrier",
          align: "center",
          sortable: false,
          width: "10%",
        },
        { text: "CSR", value: "csr", align: "center", sortable: false },
        {
          text: "Equipment",
          value: "equipment",
          align: "center",
          sortable: false,
        },
        {
          text: "Origin",
          value: "shipper",
          align: "center",
          sortable: false,
        },
        {
          text: "Destination",
          value: "consignee",
          align: "center",
          sortable: false,
        },
        {
          text: "Customer Price",
          value: "customerPrice",
          align: "center",
          sortable: false,
        },
        {
          text: "Fuel Surcharge",
          value: "fuelSurcharge",
          align: "center",
          sortable: false,
        },
        {
          text: "Customer Total",
          value: "customerTotalPrice",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "edit",
          align: "center",
          sortable: false,
          width: "2%",
        },
        {
          text: "",
          value: "receivePayment",
          align: "center",
          sortable: false,
        },
      ],
      receivableLoads: [],
      queryParams: {},
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
    }
  },
  computed: {
    ...mapGetters("generic", ["loadingIcon", "agentDetail"]),
    ...mapGetters("billing", ["receivableLoadList"]),
  },
  watch: {
    receivableLoadList() {
      this.totalRecord = this.receivableLoadList.count
      this.receivableLoads = this.receivableLoadList.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.receivableLoads = []
    this.getReceivableLoads()
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split("-")
      return `${month}/${day}/${year.toString().substr(-2)}`
    },
    parseTime(time) {
      if (!time) return null

      return moment(time, "HH:mm").format("HH:mm")
    },
    getReceivableLoads() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.queryParams.ordering = "delivery_date"
      this.$store.dispatch("billing/GET_RECEIVABLE_LOADS", this.queryParams)
    },
    resetSearch() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.searchParam.loadNumber = null
      this.searchParam.customerRefId = null
      this.searchParam.customerId = null
      this.$store.commit("generic/setCustomerId", null)
      this.queryParams.ordering = "delivery_date"
      this.$store.dispatch("billing/GET_RECEIVABLE_LOADS", this.queryParams)
    },
    searchAllReceivableLoads() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.queryParams.ordering = "delivery_date"
      if (this.searchParam.loadNumber) {
        this.queryParams.id = this.searchParam.loadNumber
      }
      if (this.searchParam.customerRefId) {
        this.queryParams.customer_reference_id__icontains =
          this.searchParam.customerRefId
      }
      if (this.searchParam.customerId) {
        this.queryParams.customer_company = this.searchParam.customerId
      }
      this.$store.dispatch("billing/GET_RECEIVABLE_LOADS", this.queryParams)
    },
    loadStatusBtnClass(loadStatus) {
      loadStatus = (
        loadStatus[0].toLowerCase() + loadStatus.substring(1)
      ).replace(" ", "")
      if (loadStatus === "plan" || loadStatus === "needsAppt") {
        return "load-status-edit-button font-weight-bold yellow--text darken-2"
      } else if (loadStatus === "available") {
        return "load-status-edit-button font-weight-bold green--text darken-2"
      } else if (loadStatus === "booked") {
        return "load-status-edit-button font-weight-bold indigo--text lighten-1"
      } else if (loadStatus === "confirmed") {
        return "load-status-edit-button font-weight-bold purple--text lighten-1"
      } else if (
        loadStatus === "picked" ||
        loadStatus === "pickOnTrack" ||
        loadStatus === "loading"
      ) {
        return "load-status-edit-button font-weight-bold blue--text lighten-1"
      } else if (
        loadStatus === "delivered" ||
        loadStatus === "deliveryOnTrack" ||
        loadStatus === "unloading" ||
        loadStatus === "readyToBill"
      ) {
        return "load-status-edit-button font-weight-bold green--text lighten-1"
      } else if (
        loadStatus === "claim" ||
        loadStatus === "deliveryOffTrack" ||
        loadStatus === "pickOffTrack"
      ) {
        return "load-status-edit-button font-weight-bold red--text darken-1"
      } else if (loadStatus === "invoiced") {
        return "load-status-edit-button font-weight-bold blue-grey--text darken-2"
      } else {
        return "load-status-edit-button font-weight-bold black--text"
      }
    },
    readDataFromAPI(value) {
      if (this.receivableLoads.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch("billing/GET_RECEIVABLE_LOADS", this.queryParams)
      }
    },
    selectedCustomer(value) {
      if (value.customer) {
        if (value.flag === 1) {
          this.searchParam.customerId = value.customer.id
          this.searchAllReceivableLoads()
        } else {
          this.searchParam.customerId = value.customer.id
        }
      }
    },
    confirmChangeLoadStatus(loadId, customerTotalPrice) {
      this.loadId = loadId
      this.customerTotalPrice = customerTotalPrice
      this.dialog = true
    },
    loadAmountReceived() {
      const payload = {
        id: this.loadId,
        data: {
          payment_received: true, // Change status Invoice to Received
        },
      }
      this.$store.dispatch("billing/RECEIVE_LOAD_PAYMENT", payload).then(() => {
        this.$store.dispatch("billing/GET_RECEIVABLE_LOADS", this.queryParams)
        const snackbar = {
          status: true,
          text: "Load payment received successfully.",
          type: snackbarType.success,
        }
        this.$store.commit("main/SET_SNACKBAR", snackbar)
      })
      this.dialog = false
    },
    editCarrierARInfo(carrier) {
      this.$store.commit("billing/setSelectedCarrier", carrier)
      this.$store.commit("billing/setDialogAREdit", true)
    },
    oprationEvent() {
      this.$store.dispatch("billing/GET_RECEIVABLE_LOADS", this.queryParams)
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
.load-status-edit-button {
  width: 65px;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
